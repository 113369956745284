.pagina-principal .header{
    border-bottom: 0.5px solid var(--card-color);
    background: transparent;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-principal h1 {
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pagina-principal .header span {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pagina-principal .header p {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin: 0;
    height: 24px;

}

.pagina-principal .header label {
    width: 40%;
    margin-left: 20px;
}

.pagina-principal .header svg {
    cursor: pointer;
}

.pagina-principal .header .col-md-2 {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.pagina-principal .body{
    border-bottom: 0.5px solid var(--card-color);
    height: 350px;
    background: transparent;
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
}
.pagina-principal .body h2 {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 1px;
}

.pagina-principal .body .svg-circle{
    border: 2px solid var(--text-primary);
    border-radius: 100px;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-circle-color);
}

.pagina-principal .body .svg-circle:hover{
    background-color: var(--bg-circle-hover);
}

.pagina-principal .body .svg-circle:hover svg path{
  fill: var(--bg-circle-text-color-hover);
}

.pagina-principal .body a p{
    text-align: center;
    width: 100px;
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 11px !important;
    letter-spacing: 1px !important;
}

.pagina-principal a{
    text-decoration: none;
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
}

.pagina-principal .body .recent-icons{
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    background-color: var(--recent-icons-background);
    color: var(--recent-icons-color);
    border: var(--recent-icons-border);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-principal .body .recent-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
}

.pagina-principal .body .recent-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pagina-principal .body p{
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.5px;
    margin: 10px 0 5px 0;
}

.pagina-principal .body span{
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
}

.pagina-principal .footer {
    color: var(--text-primary);
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
}

.pagina-principal .footer .card-footer{
    width: 220px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--card-color);
    padding: 0;
    margin: 15px 15px 0 0;
}

.pagina-principal .footer .card-footer img{
    width: 100%;
}

.pagina-principal .footer .card-footer h3{
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.5px;
    margin-top: 30px;
    margin-left: 10px;
}

.pagina-principal .footer .card-footer p {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.5px;
    margin-top: 10px;
    margin-left: 10px;
}

.pagina-principal a{
    text-decoration: none;
    color: var(--text-primary);
}

.pagina-principal a:hover{
    text-decoration: none;
    color: var(--text-primary);
}

.pagina-principal .disabled{
    background-color: var(--disabled-color) !important;
}

.pagina-principal .header .balance-text {
    font-size: 24px;
    margin: 0;
    margin-bottom: 5px;
}

.pagina-principal .header .balance-placeholder {
    padding-left: 60px;
}

.pagina-principal .header .toggle-balance-icon {
    position: absolute;
    margin-left: 300px;
    cursor: pointer;
}

.pagina-principal .header .details-toggle {
    font-size: 12px;
    color: var(--recent-icons-background);
    padding-bottom: 20px;
    display: block;
}

.pagina-principal .header .details-available {
    margin-left: auto;
    padding-left: 60px;
    margin-top: 0px;
}

.pagina-principal .header .details-blocked {
    margin-left: auto;
    padding-left: 60px;
}

.container {
    margin-bottom: 10px;
    margin-left: -10px;
}

.empty-space {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.balance-section {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 15px;
}

.balance-placeholder {
    padding-left: 60px;
}

.details-available {
    margin-left: auto;
    padding-left: 60px;
}

.details-blocked {
    margin-left: auto;
    padding-left: 60px;
}

.details-toggle {
    font-size: 12px;
    color: var(--recent-icons-background);
    padding-bottom: 20px;
    display: block;
}
