.pagina-contato{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}


.pagina-contato-container{
    height: 100%;
}

.pagina-contato .pagina-contato-container .body{
    color: var(--text-primary);
}

.pagina-contato .pagina-contato-container .body h1{
    text-align: center;
    font-size: 1.75rem;
    margin-top: 20px;
}

.pagina-contato .pagina-contato-container .body .title{
    color: var(--auxiliary-color) !important;
    font-weight: 700;
    font-size: 18px;
}
.title a{
  color: var(--auxiliary-color) !important;
  font-weight: 700;
  font-size: 18px;
}
.mt-20 {
  margin-top: 20px;
}
.center {
  text-align: center;
}
.pagina-contato .pagina-contato-container .body .subtitle{
    font-weight: 700;
    font-size: 16px;
}

.pagina-contato .pagina-contato-container .body .info-area{
    display: flex;
    flex-direction: row;
}

.pagina-contato .pagina-contato-container .logo-area{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-contato .pagina-contato-container img{
    width: 250px;
    position: relative;
    margin-bottom: 4rem;
}

.pagina-contato .pagina-contato-container h1{
    position: relative;
}

.pagina-contato .pagina-contato-container .header{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-contato .header .col-xl-12 p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.pagina-contato .header .col-xl-12 a{
    color: var(--auxiliary-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pagina-contato .header .col-xl-12 label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}
