.contato-container {
  max-width: 8.5rem;
  min-width: 8.5rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  color: #FFF;
  font-family: 'Poppins', sans-serif;
}

.contato-avatar {
  width: 3.5rem;
  height: 3.5rem;
  background-color: var(--recent-icons-background);
  color: var(--recent-icons-color);
  border: var(--recent-icons-border);
  border-radius: 9999px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.contato-infos {
  text-align: center;

  display: flex;
  flex-direction: column;
  color: var(--text-primary);
}

.contato-infos > strong {
  font-size: 0.875rem;
  font-weight: 600;
}

.contato-infos > span {
  font-size: 0.75rem;
  font-weight: 400;
}
