.keys-modal{
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--text-primary);
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
}

.overlay-keys-modal{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: rgba(0, 0, 0, 0.40);
}

.keys-modal-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.keys-modal-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.keys-modal-section > h3 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  font-size: 1rem;
  font-weight: 600;
}

.my-keys {
  max-height: 280px;
  padding: 0 0.5rem 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  overflow: auto;
}

.my-keys::-webkit-scrollbar {
  width: 0.2rem;
}

.my-keys::-webkit-scrollbar-thumb {
  background-color: var(--gray-300);
  border-radius: 20px;
}

.new-key-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.new-key-item {
  padding: 1rem 1.5rem;
  border-bottom: 2px solid #737B8B;
}

.new-key-radio-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.new-key-radio-item {
  padding: 1rem 1.5rem;
  border-bottom: 2px solid #737B8B;

  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.new-key-radio-button {
  width: 20px;
  height: 20px;
  background: transparent;
  border: 3px solid var(--text-primary);
  border-radius: 4px;
}

.new-key-radio-button:hover {
  background: #737B8B;
}

.new-key-radio-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--auxiliary-color);
}

.new-key-radio-label {
  display: flex;
  flex-direction: column;

  font-size: 0.875rem;
  font-weight: 400;
}

.new-key-button {
  all: unset;
  max-width: 12.5rem;
  padding: 0.5rem 1rem;
  background: var(--pix-keys-manage-button-background);
  border: 1px solid var(--pix-keys-manage-button-border);
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--pix-keys-manage-button-color);
  font-weight: 600;
}

.pix-new-key-container {
  padding: 1rem 1.5rem;
  background: transparent;
  border: 1px solid #737B8B;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-key-item-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.pix-new-key-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pix-new-key-content {
  display: flex;
  flex-direction: column;

  font-size: 0.875rem;
  font-weight: 400;
}

.pix-new-key-status {
  padding: 0.125rem 0.5rem;
  /* background: #E0FAEF; */
  border: 1px solid #737B8B;
  border-radius: 4px;

  color: var(--text-primary);
  font-size: 14px;
  font-weight: 600;
}

.resend-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
.resend-container.timer-container {
  flex-direction: column;
  align-items: center;
}

.resend-container .title, .timer-container .subtitle {
  margin-right: 7px;
  font-size: 0.9rem;
  font-style: italic !important;
}
.resend-container .title strong {
  font-size: 1.1rem;
}

.resend-container .btn-resend{
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--primary-color);
}

.resend-container .timer {
  font-weight: bolder;
  font-size: 1.2rem;
  line-height: 22px;
}

.timer-content {
  display: flex !important;
  flex-direction: row;
}

.validation-code-error {
  color: red;
}
.button-send-code-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.button-send-code-container button {
  display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  border-radius: 4px;
    border: 1px solid var(--pix-modal-confirm-button-border);
    background: var(--pix-modal-confirm-button-background);
    color: var(--pix-modal-confirm-button-color);
}
