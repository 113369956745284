

.page-header-container {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.page-header-container > span {
  color: var(--text-primary);
  font-size: 1.375rem;
}

.page-header-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  font-size: 0.75rem;
}

.page-header-nav > a {
  text-decoration: none;
  color: var(--header-link-color);
}

.page-header-nav > a:hover {
  color: var(--header-link-color);
}

.arrow-icon {
  color: var(--header-link-color);
}

.page-header-nav > span {
  color: var(--text-primary);
}
