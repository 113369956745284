
.box-modal-container {
  position: relative;
  padding: 2.5625rem 2.0625rem;
  background: var(--bg-modal-color);
  border-radius: 12px;
  border: 1px solid var(--border);

  color: var(--text-primary);
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;

  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  overflow-y: auto;
}

.box-modal-title {
  font-size: 1.375rem;
  font-weight: 400;
}

.close-keys-modal {
  position: absolute;
  top: 10px;
  right: 10px;
}

.box-modal-header {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.box-modal-header > img {
  width: 4.6875rem;
  height: 4.6875rem;
}

.box-modal-header > p {
  font-size: 1rem;
  font-weight: 400;
}

.confirmation-code-container {
  display: flex;
  flex-direction: column;
}

.confirmation-code-container  label{
  font-weight: bold;
}
.confirmation-code-container  input{
  margin: 5px 0;
}
.confirmation-code-container  span{
  color: brown;
}

.button-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
