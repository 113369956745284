
.qrcode-modal-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: var(--text-primary);
    margin-top: 30px;
}

.qrcode-modal-container h1{
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.qrcode-modal-container img{
    width: 250px;
    height: 250px;
    margin-bottom: 30px;
}


.qrcode-modal-container button{
    display: flex;
    width: 180px;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.qrcode-modal-container .confirm-button{
    border-radius: 4px;
    border: 1px solid var(--pix-modal-confirm-button-border);
    background: var(--pix-modal-confirm-button-background);
    color: var(--pix-modal-confirm-button-color);
    width: 250px;
}

.qrcode-modal-container .cancel-button{
    border-radius: 4px;
    border: 2px solid var(--pix-modal-cancel-button-border);
    color: var(--pix-modal-cancel-button-color);
    background: var(--pix-modal-cancel-button-background);
}

.qrcode-modal-container .input-area-icon{
    display: flex;
    justify-content: center;
}

.qrcode-modal-container .input-area{
    border-radius: 4px;
    border: 1px solid var(--text-primary);
    background: #FFFFFF;
    display: flex;
    height: 38px;
    align-items: center;
}

.qrcode-modal-container .input-area input{
    background: transparent;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
}

.qrcode-modal-container .button-area{
    display: flex;
    gap: 15px;
    margin-top: 30px;
}

.qrcode-modal-container .qrcode-area{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qrcode-modal-container p{
    text-align: start;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    margin: 0;
}

.qrcode-modal-container .copiaecola-button{
    width: 280px;
    border: 1px solid var(--pix-modal-confirm-button-border);
    background: var(--pix-modal-confirm-button-background);
    color: var(--pix-modal-confirm-button-color);
    font-weight: 600;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
}
