.step1-content {
  padding-right: 0.5rem;
  overflow: auto;
}

.step1-content::-webkit-scrollbar {
  width: 0.2rem;
}

.step1-content::-webkit-scrollbar-thumb {
  background-color: var(--gray-300);
  border-radius: 20px;
}

.step1-content-body > h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

.fine-select-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  font-family: 'Poppins', sans-serif;
}

.fine-select-container > label {
  font-size: 0.875rem;
  font-weight: 400;
}

.fine-select-container > label > span {
  color: var(--red);
}

.fine-select-content {
  width: 100%;
  max-height: 2.6875rem;
  background: var(--generate-ticket-select-background);
  border: 1px solid var(--generate-ticket-select-border);
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.875rem;
  color: var(--generate-ticket-select-color);
}

.fine-select-content > select {
  height: 43px;
  width: 100%;
  background-color: var(--generate-ticket-select-background);
  color: var(--generate-ticket-select-color);
}

.fine-select-content > select > option {
  background-color: var(--generate-ticket-select-background);
  color: var(--generate-ticket-select-color);
}

.cobrar-boleto-box {
  max-height: 90%;
}
