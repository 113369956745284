.box-container {
  max-width: 46.25rem;
  padding: 2.5rem 2rem;
  background-color: var(--bg-modal-color);
  border: 1px solid var(--modal-border-color);
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  font-family: 'Poppins', sans-serif;
}

.box-header {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.box-header > h2,
.box-header > p {
  margin: 0;
}

.box-header > img {
  width: 4.6875rem;
  height: 4.6875rem;
}

.box-header > h2 {
  font-size: 1.375rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.box-header > p {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}