
.transferencia-ted{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.transferencia-ted .transferencia-ted-container .header{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
}

.transferencia-ted .header .col-xl-12 p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.transferencia-ted .header a{
    text-decoration: none;
}

.transferencia-ted .header .col-xl-12 a{
    color: var(--header-link-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.transferencia-ted .header .col-xl-12 label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.transferencia-ted .body {
    background: transparent;
    color: var(--text-primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.transferencia-ted .transferencia-ted-container .body-h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.transferencia-ted .transferencia-ted-container .body strong{
    color: var(--auxiliary-color);
}

.transferencia-ted .transferencia-ted-container .body .checkbox{
    display: flex;
    gap: 5px;
}

.transferencia-ted .transferencia-ted-container .body .checkbox input{
    all:unset;
    position:relative;
    display: inline-block;
    border: solid 3px var(--text-primary);
    border-radius: 4px;
    width:20px;
    height: 20px;
    background: var(--bg-color);
    cursor:pointer;
  }

  .transferencia-ted .transferencia-ted-container .body .checkbox input:checked{
    border: solid 1px var(--text-primary);
    padding: 2px;
    background: var(--auxiliary-color)
  }

  .transferencia-ted .transferencia-ted-container .body .checkbox input:checked::after{
    content:"✓";
    position:absolute;
    width:20px;
    height:20px;
    top: -4px;
    left: 4.5px;
    color: var(--text-primary);
    font-weight: 800;
    font-size: 20px;

  }

.transferencia-ted .body .input-row{
    display: flex;
    gap: 15px;
    margin: 15px 0 15px 0;
}

.transferencia-ted .body .input-area{
    border-radius: 4px;
    border: 1px solid var(--text-primary);
    background: transparent;
    display: flex;
    height: 38px;
    align-items: center;
}

.transferencia-ted .body .input-area .input-area-icon{
    height: 100%;
    display: flex;
    align-items: center;
}

.transferencia-ted .body .input-area label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.transferencia-ted .body .input-area svg{
    margin-left: 10px;
    color: #000;
}

.transferencia-ted .body .input-area input,
.transferencia-ted .body .input-area select{
    background:  var(--bg-input-color);
    color: var(--input-color) !important;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
}

.transferencia-ted .body .input-area input::placeholder{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.transferencia-ted .body .input-area input:focus{
    box-shadow: 0 0 0 0;
    outline: 0;
}

.transferencia-ted .transferencia-ted-container .button-area{
    display: flex;
    justify-content: space-between;
    width: 88%;
}

.button-area button{
    display: flex;
    width: 180px;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-weight: 600;
}

.transferencia-ted .transferencia-ted-container .body .confirm-button{
    border-radius: 4px;
    border: 1px solid var(--ted-transfer-confirm-button-border);
    background: var(--ted-transfer-confirm-button-background);
    color: var(--ted-transfer-confirm-button-color);
}

.transferencia-ted .transferencia-ted-container .body .cancel-button{
    background-color: var(--ted-transfer-cancel-button-background);
    border: 2px solid var(--ted-transfer-cancel-button-border);
    color: var(--ted-transfer-cancel-button-color);
    font-weight: 600;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
}

.transferencia-ted .body .receipt{
    height: 100%;
    width: 100%;
}

.transferencia-ted .transferencia-ted-container .body .col-md-1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.transferencia-ted .transferencia-ted-container .body .col-md-1 p{
    font-size: 30px;
    margin: 0;
    line-height: 30%;
}

.step-buttons .step3-buttons a.step-button {
    all: unset;
    padding: 0.375rem 1rem;
    min-width: 11.25rem;

    background: transparent;
    border: 1px solid var(--border-color);
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    text-decoration: none;
    color: var(--text-secondary);
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.5px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.overlay-password-modal{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999999;
    background: rgba(0, 0, 0, 0.40);
}

.password-modal{
    position: relative;
    z-index: 9999;
    width: 400px;
    height: 250px;
    flex-shrink: 0;
    background: var(--bg-auxiliary-color);
    border-radius: 12px;
    border: 1px solid var(--modal-border-color);
    margin-top: 100px;
    margin-left: 340px;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-modal .password-modal-header {
    padding: 41px 33px;
}

.password-modal .password-modal-header h1{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px; /* 59.091% */
    letter-spacing: 0.5px;
}


.password-modal button{
    display: flex;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.password-modal .confirm-button{
    border-radius: 4px;
    border: 1px solid var(--ted-transfer-confirm-button-border);
    background: var(--ted-transfer-confirm-button-background);
    color: var(--ted-transfer-confirm-button-color);
}

.password-modal .cancel-button{
    border-radius: 4px;
    border: 1px solid var(--ted-transfer-cancel-button-border);
    background: var(--ted-transfer-cancel-button-background);
    color: var(--ted-transfer-cancel-button-color);
}

.password-modal .input-area{
    border-radius: 4px;
    border: 1px solid var(--text-primary);
    background: var(--bg-input-color);
    display: flex;
    height: 38px;
    align-items: center;
}

.password-modal .input-area input{
    background: transparent;
    color: var(--text-primary);
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
}

.password-modal .input-area input:focus{
    box-shadow: 0 0 0 0;
    outline: 0;
}

.bank-select-container {
    width: 100%;

}

.bank-select {
    color: #000;
    cursor: pointer;
}

.bank-select:focus {
    outline: none;
    border: none;
}
