.cadastro-chave-pix{
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.cadastro-chave-pix-container{
    height: 100%;
}

.cadastro-chave-pix .cadastro-chave-pix-container .header{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cadastro-chave-pix .cadastro-chave-pix-container .header a {
    text-decoration: none;
}

.cadastro-chave-pix .header .col-xl-12 p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.cadastro-chave-pix .header .col-xl-12 a{
    color: var(--auxiliary-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.cadastro-chave-pix .header .col-xl-12 label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.cadastro-chave-pix .body h1 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}


.cadastro-chave-pix .input-area-icon{
    display: flex;
    justify-content: center;
}

.cadastro-chave-pix .input-area{
    border-radius: 4px;
    border: 1px solid var(--text-primary);
    background: var(--text-primary);
    display: flex;
    height: 38px;
    align-items: center;
}

.cadastro-chave-pix .input-area input, select{
    color: #000;
    background: transparent !important;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
}