.input-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  font-family: 'Poppins', sans-serif;
  position: relative;
}

.input-container > label {
  font-size: 0.875rem;
  font-weight: 400;

  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.input-container > label > span {
  color: var(--auxiliary-color);
}

.input-container > p {
  margin-bottom: 0;

  font-size: 0.75rem !important;
  font-weight: 400;
}

.input-container > span {
  font-size: 0.75rem !important;
  font-weight: 400;
  color: #F2383A;
  position: flex;
}

.input-with-icon {
  width: 100%;
  padding: 0.625rem 0.75rem;
  background-color: var(--bg-input-color);
  border: 1px solid var(--text-primary);
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input-with-icon > svg {
  color: var(--input-color);
}

.input-with-icon > input {
  flex: 1;
  background-color: var(--bg-input-color);
  color: var(--input-color);
  border: 0;

  font-size: 0.875rem;
  font-weight: 400;
}

.input-with-icon > input:focus {
  outline: 0;
}
