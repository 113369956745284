.componente-acesso .acesso-rapido{
    margin-top: 15px;
}

.componente-acesso .acesso-rapido h1{
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 1px;
    text-align: left;
    margin-left: 37px;
    opacity: 0.2;
}

.componente-acesso .tipo-acesso{
    color: #FFF !important;
    width: 200px;
    height: 42px;
    flex-shrink: 0;
    display: flex;
    margin-left: 20px;
    padding: 5px 15px;
    border-radius: 5px;

}

.componente-acesso .disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.componente-acesso .tipo-acesso:hover{
    background-color: rgb(255, 255, 255, 0.1);
}

.componente-acesso .tipo-acesso svg:hover{
    color: var(--menu-active-icon-color);
}

.componente-acesso .col-md-2{
    display: flex;
    align-items: center;
}

.componente-acesso .col-xl-7{
    display: flex;
    align-items: center;
}

.componente-acesso p{
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.5px;
    margin: 0;
}

.componente-acesso a{
    text-decoration: none;
}

.componente-acesso .button-area button{
    bottom: 0;
    position: absolute;
    margin-left: 20px;
}

.componente-acesso span {
    margin-left: 10px;
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.5px;
}

.config-button .tipo-acesso p{
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 12px !important;
    letter-spacing: 0.5px !important;
    margin: 0 !important;
}



.config-button .tipo-acesso:hover{
    background-color: rgb(255, 255, 255, 0.1);
}

a.config-button.active .link-icon {
    color: var(--menu-active-icon-color);
}

a.config-button.active .tipo-acesso {
    background-color: rgb(255, 255, 255, 0.1);
}
