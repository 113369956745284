.historico-pix-item{
    color: var(--text-primary);
    display: flex;
    height: 100px;
    
}

.historico-pix-item .col-xl-1{
    display: flex;
    flex-direction: column;
}

.historico-pix-item .circle{
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: var(--text-primary);
}

.historico-pix-item .column{
    width: 2px;
    height: 100%;
    border-radius: 100px;
    margin-left: 4px;
    background-color: var(--text-primary);
}

.historico-pix-item .name-area h2{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.historico-pix-item .name-area p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.historico-pix-item .taxnumber-area{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.historico-pix-item .taxnumber-area p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.historico-pix-item .value-area{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.historico-pix-item .value-area p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.historico-pix-item .value-area h2{
    color: #DC3545;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.historico-pix-item p{
    line-height: 13px;
    letter-spacing: 0.5px;
    margin-top: 10px;
}

.historico-pix-item .col-xl-3 p{
    margin: 3px 0;
}
