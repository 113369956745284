@import url('./theme.css');

@font-face {
  font-family: 'Poppins';
  src: url('../assets/Poppins-Regular.ttf') format('truetype'); 
} 

body {
  background: var(--bg-color);
  color: var(--text-primary);
}

body::-webkit-scrollbar {
  width: 0.3rem;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--gray-300);   
  border-radius: 20px;  
}

html, body, #root{
  height: 100%;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: Poppins;
  font-style: normal;
}