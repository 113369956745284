.pagina-ted-container {
    height: 100%;
    padding: 1.5rem 8.75rem 0 8.75rem;

    color: var(--white);
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.5px;
}

.pagina-ted-header {
    margin-bottom: 3rem;
}

.pagina-ted-content {
    display: flex;
    flex-direction: column;
    gap: 3.4375rem;
}

.pagina-ted-content-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.pagina-ted-container .pagina-ted-content .pagina-ted-content-actions .btn-contatos{
  background-color: var(--pix-transfer-contact-button-background);
  border: 2px solid var(--pix-transfer-contact-button-border);
  color: var(--pix-transfer-contact-button-color);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
}

.pagina-ted-container .pagina-ted-content .pagina-ted-content-actions .btn-contatos-disabled{
  background-color: var(--disabled-color);
  border: 2px solid var(--disabled-color);
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  opacity: 0.5;
}

.pagina-ted-content-actions > button,
.pagina-ted-content-actions > a {
    all: unset;
    min-width: 11.25rem;
    padding: 0.375rem 1rem;
    background: var(--disabled-color);
    border: 0;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    cursor: pointer;
}

.pagina-ted-content-actions > a:hover {
    color: #FFF;
}

.pagina-ted-content-actions > button:disabled,
.pagina-ted-content-actions > a:disabled {
    cursor: not-allowed;
}

.pagina-ted-content-actions > a.content-actions-button-red,
.pagina-ted-content-actions > button.content-actions-button-red {
  background-color: var(--ted-page-confirm-button-background);
  border: 2px solid var(--ted-page-confirm-button-border);
  color: var(--ted-page-confirm-button-color);
  font-weight: 600;
}

.ted-contatos-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.ted-contatos-container > span {
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 1px;
}

.ted-contatos-list {
    max-width: 98%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: nowrap;
    padding-bottom: 1rem;

    overflow-x: scroll;
}

.ted-contatos-list::-webkit-scrollbar {
    height: 0.5rem;
}

.ted-contatos-list::-webkit-scrollbar-thumb {
    background-color: var(--gray-300);
    border-radius: 20px;
}

.ted-contatos-list > a {
    all: unset;
    cursor: pointer;
}

.pagina-ted{
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.pagina-ted-container{
    height: 100%;
}

.pagina-ted .pagina-ted-container .header{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-ted .pagina-ted-container .header a {
    text-decoration: none;
}

.pagina-ted .header .col-xl-12 p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.pagina-ted .header .col-xl-12 a{
    color: var(--header-link-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pagina-ted .header .col-xl-12 label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pagina-ted .pagina-ted-container a {
    text-decoration: none;
}

.pagina-ted .pagina-ted-container .body .button-area{
    display: flex;
    gap: 15px;
}

.pagina-ted .pagina-ted-container button{
    display: flex;
    width: 180px;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.pagina-ted .pagina-ted-container .body h2{
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 1px;
}

.pagina-ted .body .recent-icons{
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    background-color: var(--recent-icons-background);
    color: var(--recent-icons-color);
    border: var(--recent-icons-border);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-ted .body .recent-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    margin-top: 15px;
}

.pagina-ted .body .recent-content{
    display: flex;
    flex-direction: column;
}

.pagina-ted .body p{
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.5px;
    margin: 10px 0 5px 0;
    text-align: center;
}

.pagina-ted .body span{
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
}
