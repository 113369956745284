.settings-container {
  display: flex;
  flex-direction: column;
  color: var(--text-primary);
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
}

.settings-header {
  padding: 1.5rem 0 1.5rem 8.75rem;
}

.settings-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.settings-section {
  padding: 2.5rem 0 2.5rem 8.75rem;
  border-bottom: 1px solid var(--card-color);

  display: flex;
  align-items: flex-start;
  gap: 3.5rem;
}

.settings-section-title {
  max-width: 15.8125rem;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  font-size: 1.375rem;
  font-weight: 400;
}

.settings-section-title > span {
  max-width: 12.8125rem;
  width: 100%;
}

.settings-avatar-content {
  display: flex;
  align-items: center;
  gap: 3.375rem;
}

.settigs-avatar {
  width: 5.4375rem;
  height: 5.4375rem;
  background: var(--primary-color);
  color: var(--change-password-modal-button-color);
  border-radius: 99999px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.settigs-avatar > img {
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 99999px;
  object-fit: cover;
}

.avatar-input-file > input[type="file"] {
  display: none;
}

.settings-avatar-button {
  all: unset;
  padding: 0.5rem 1rem;
  background: var(--primary-color);
  color: var(--change-password-modal-button-color);
  border-radius: 12px;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.875rem;
  font-weight: 600;

  cursor: pointer;
}

.button-without-bg {
  background: var(--delete-photo-button-background);
  color: var(--delete-photo-button-color);
  border: var(--delete-photo-button-border);
}

.settings-contanct-content {
  max-width: 19.375rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
}

.without-border {
  border: 0;
}

.settings-button {
  max-width: 9.75rem;
  border-radius: 12px;
  border: 2px solid var(--update-info-settings-button-border);
  background: var(--update-info-settings-button-background);
  color: var(--update-info-settings-button-color);

  display: inline-flex;
  flex-direction: row;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.875rem;
  font-weight: 600;
}

.change-password-button{
  max-width: 9.75rem;
  border-radius: 12px;
  display: inline-flex;
  flex-direction: row;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.875rem;
  border: 1px solid var(--change-password-modal-button-border);
  background: var(--change-password-modal-button-background);
  color: var(--change-password-modal-button-color);
  font-weight: 600;
}
