
.copiaecola-modal-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: var(--text-primary);
}

.copiaecola-modal-container .col-md-5{
    padding: 30px 0px;
}

.copiaecola-modal-container h1{
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.copiaecola-modal-container button{
    display: flex;
    width: 35px;
    height: 35px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.copiaecola-modal-container .confirm-button{
    border-radius: 4px;
    border: 1px solid var(--pix-modal-confirm-button-border);
    background: var(--pix-modal-confirm-button-background);
    color: var(--pix-modal-confirm-button-color);
}

.copiaecola-modal-container .cancel-button{
    border-radius: 4px;
    border: 2px solid var(--pix-modal-cancel-button-border);
    color: var(--pix-modal-cancel-button-color);
    background: var(--pix-modal-cancel-button-background);
}

.copiaecola-modal-container .input-area-icon{
    display: flex;
    justify-content: center;
    color: #000;
}

.copiaecola-modal-container .input-area{
    border-radius: 4px;
    border: 1px solid var(--text-primary);
    background: #FFFFFF;
    display: flex;
    height: 38px;
    align-items: center;
}

.copiaecola-modal-container .input-area input{
    background: transparent;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
}

.copiaecola-modal-container .button-area{
    display: flex;
    gap: 15px;
    margin-top: 30px;
}

.copiaecola-modal-container .receipt-items label {
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
}

.copiaecola-modal-container .receipt-items h4 {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    margin: 0;
}

.copiaecola-modal-container .receipt-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.receipt-footer .share-button{
    display: flex;
    flex-direction: row;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    align-items: center;
    height: 38px;
    width: 340px;
    padding: 8px 16px;
    gap: 8px;
    text-decoration: none;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--share-receipt-button-border);
    background:  var(--share-receipt-button-background);
    margin-top: -20px;
    color: var(--share-receipt-button-color);
}