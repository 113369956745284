.change-modal {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--text-primary);
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
}

.overlay-change-password-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.40);
}

.change-password-container {
  max-width: 19.375rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.change-password-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.change-password-section > h3 {
  font-size: 1rem;
  font-weight: 600;
}

.password-change-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.password-change-button {
  display: flex;
  height: 2.375rem;
  width: 12rem;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--change-password-modal-button-border);
  background: var(--change-password-modal-button-background);
  color: var(--change-password-modal-button-color);
  font-weight: 600;
}

.password-validation-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.password-validation-container > span {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 0.75rem;
  font-weight: 500;
}

.error-validation {
  color: var(--primary-color);
}

.check-validation {
  color: #32D16D;
}

.password-confirmation > span {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
