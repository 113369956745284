.pix-key-card{
    display: flex;
    border-radius: 10px;
    border: 2px solid #737B8B;
    color: var(--text-primary);
    padding: 10px 0px;
}

.pix-key-card .key-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.pix-key-card .key-infos{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 5px;
}

.pix-key-card .key-share{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.pix-key-card .key-share svg{
    cursor: pointer;
    color: var(--var(--text-primary)-color);
}
.pix-key-card .key-infos .info-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.separator{
  font-size: 14px;
  margin: 0 5px;
}
.pix-key-card .key-infos .info-header .info-status-title {
  font-size: 14px;
  margin: 0 5px;
}
.pix-key-card .key-infos .info-header .info-status-value {
  font-size: 14px;
  margin: 0 5px;
  font-weight: bold;
}
.pix-key-card .key-infos .info-header .info-status-button {
  margin: 0 5px;
}

.pix-key-card .key-infos .info-header .info-status-button button {
  /* background-color: var(--menu-active-icon-color); */
  background-color: var(--primary-color);
  padding: 2px 7px;
  font-size: 11px;
  border-radius: 3px;
}
.pix-key-card h2{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    margin: 0;
}

.pix-key-card h1{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 13px !important;
    letter-spacing: 0.5px !important;
    margin: 0;
}
