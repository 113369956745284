.fundo-selecionar .icone-documento{
    padding-top:150px ;
    font-size: 100px;
    text-align: center;

}

.extrato-item{
    color: #ADB5BD;
    display: flex;
}

.extrato-item .col-xl-1{
    display: flex;
    flex-direction: column;
}

.extrato-item .circle{
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: #ADB5BD;
}

.extrato-item .column{
    width: 2px;
    height: 100%;
    border-radius: 100px;
    margin-left: 4px;
    background-color: #ADB5BD;
}

.extrato-item h2{
    font-weight: 600 !important;
}

.extrato-item p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
    margin-top: 10px;
}

.extrato-item .col-xl-3 {
    text-align: right;
}

.extrato-item .col-xl-3 p{
    margin: 3px 0;
}

.extrato-item .value-area{
    display: flex;
    justify-content: end;
    gap: 5px;
    margin: 0 13px 20px 0;
    align-items: center;
}

.extrato-item .info-area{
    margin: 0px 50px 20px 0;
}

.extrato-item svg{
    cursor: pointer;
}
