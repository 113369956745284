.menu {
    background: var(--bg-secondary-color);
    width: 240px;
    color: #FFF;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    height: 100%;
}

.menu .menu-name{
  display: flex;
  align-items: center;
}

.menu .header-menu{
    background-color: var(--secondary-color);
    width: 100%;
    height: 64px;
    padding: 10px 18px;
}

.menu .header-menu div:last-child{
    margin: 0;
    margin-left: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu .client-icon{
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background-color: var(--auxiliary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu .client-icon > img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 99999px;
    object-fit: cover;
}

.menu .conta{
    display: flex;
    padding: 15px 25px;
}

.menu .conta p{
    margin: 0;
}

.menu .col-xl-6{
    width: 45%;
    padding: 0px;
}

.menu .saldo .col-xl-6 p{
    margin: 7px 0px 0px 34px;
    font-size: 12px;
    font-weight: 400;
}

.menu #dinheiro {
    font-size: 20px;
    font-weight: 700;
    margin: 0px 0px 0px 33px;
}

.menu .col-xl-3.BsEyeSlash{
    padding: 20px 0px 0px 0px;
    font-size: 20px;
}

.menu .col-xl-3.FiChevronRight{
    width:30%;
    text-align: right;
    font-size: 32px;
}


