.redeem-page-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.redeem-echeck-consult-field{
  display: flex;
  justify-content: center;
  margin-top: 2.4vw;
}

.redeem-echeck-button-field {
  padding: 0 1rem;
  display: flex;
  align-items: end;
  justify-content: center;
}

.redeem-echeck-button-field button{
  margin-top: 1vw;
  font-weight: 600;
  display: flex;
  padding: 0.5vw 1vw;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--pix-modal-confirm-button-border);
  background: var(--pix-modal-confirm-button-background);
  color: var(--pix-modal-confirm-button-color);
  margin-right: 0;
  width: 13rem;
  height: 2.4rem;
}

.redeem-echeck-input-field{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.redeem-echeck-input-field .input-area{
  border-radius: 4px;
  background: #FFFFFF;
  display: flex;
  height: 38px;
  align-items: center;
}

.redeem-echeck-input-field .input-area input{
  background: transparent;
  border: none;
  height: 38px;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  padding-left: 10px;
  margin: 0;
}

.redeem-echeck-input-field .input-area input:focus{
  box-shadow: 0 0 0 0;
  outline: 0;
}

.fake-modal {
  display: flex;
}

#content {
  display: none;
}

@media (orientation: landscape) {
  #content {
    display: block;
  }
  #rotate-message {
    display: none;
  }
}

#rotate-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-size: 2em;
  padding: 0 3rem;
}

@media (max-width: 1080px) {
  .redeem-page-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .redeem-echeck-consult-field{
    margin-top: 0;
  }

  .redeem-echeck-consult-field .fake-modal{
    display: flex;
    justify-content: center;
  }

  .fake-modal {
    flex-direction: row;
    padding: 1vw 2vw;
  }
}


