.pix-key-container {
  padding: 1rem 1.5rem;
  background: transparent;
  border: 1px solid #737B8B;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  color: var(--text-primary);
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
}

.pix-key-info {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.pix-key-content {
  display: flex;
  flex-direction: column;

  font-size: 0.875rem;
  font-weight: 400;
}

.pix-key-content > strong {
  font-size: 1rem;
  font-weight: 700;
}

.copy-check {
  color: #00875F;
}

.pix-key-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.trash-button:hover {
  color: #AB222E;
}

.delete-confirm-button:hover {
  color: #00875F;
}

.key-delete-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.type-value {
  color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    margin: 0;
}

.info-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.separator{
  font-size: 14px;
  margin: 0 5px;
}
.info-header .info-status-title {
  font-size: 14px;
  margin: 0 5px;
}
.info-header .info-status-value {
  font-size: 14px;
  margin: 0 5px;
  font-weight: bold;
}
.info-header .info-status-button {
  margin: 0 5px;
}

.info-header .info-status-button button {
  /* background-color: var(--menu-active-icon-color); */
  background-color: var(--primary-color);
  padding: 2px 7px;
  font-size: 11px;
  border-radius: 3px;
  cursor: inherit;
}
