.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.page-content {
  height: 100%;
}

.header{
  margin-top: 20px;

  width: 100%;
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .col-xl-12 p{
  color: var(--text-primary);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.header .col-xl-12 a{
  color: var(--auxiliary-color);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.5px;
}

.header .col-xl-12 label{
  color: var(--text-primary);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.5px;
}

.form-container {
  /* background-color: #f3f; */
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

}

.form-container form {

}
.flex {
  display: flex;
}
.flex-col{
  flex-direction: column;
}
.flex-row{
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.just-center {
  justify-content: center;
}

.just-bet{
  justify-content: space-between;
}

.just-around {
  justify-content: space-around;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pr-2 {
  padding-right: 2px;
}
.pl-2 {
  padding-left: 2px;
}
.pr-4 {
  padding-right: 4px;
}
.pl-4 {
  padding-left: 4px;
}

.mt-10 {
  margin-top: 10px;
}
.mt-4 {
  margin-top: 4px;
}
.button-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.button-field button {
  background: var(--primary-color);
  color: var(--text-primary);
  padding: 5px 90px 7px 90px;
  border-radius: 4px;
  border: none;
}

textarea, input {
  padding-left: 3px;
}

select {

  border-color: rgb(118, 118, 118) !important;
  border: 1px solid;
}
