.item-mark {
  width: 0.75rem;
  height: 6.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mark-circle {
  all: unset;
  background-color: var(--gray-300);
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px;
}

.mark-line {
  all: unset;
  background-color: var(--gray-300);
  width: 0.125rem;
  height: 100%;
}
