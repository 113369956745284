.cobrar-boleto-container {
  padding: 1.5rem 0 5.625rem 8.75rem;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 5rem;

  color: var(--text-primary);

  /* overflow: auto; */
}

.cobrar-boleto-container::-webkit-scrollbar {
  width: 0.2rem;
}

.cobrar-boleto-container::-webkit-scrollbar-thumb {
  background-color: var(--gray-300);
  border-radius: 20px;
}

.cobrar-boleto-container .step-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cobrar-boleto-container .step-buttons > a:hover {
  background: var(--confirm-ticket-button-background);
  border: 2px solid var(--confirm-ticket-button-border);
  color: var(--confirm-ticket-button-color);
}

.cobrar-boleto-container .step-buttons > .step-button-red:hover {
  background: var(--cancel-ticket-button-background);
  border: 2px solid var(--cancel-ticket-button-border);
  color: var(--cancel-ticket-button-color);
}

.cobrar-boleto-container .step-button {
  padding: 0.375rem 1rem;
  min-width: 11.25rem;

  background: var(--cancel-ticket-button-background);
  border: 2px solid var(--cancel-ticket-button-border);
  color: var(--cancel-ticket-button-color);
  font-weight: 600;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.5px;
}

.cobrar-boleto-container .step-button-red {
  background: var(--confirm-ticket-button-background);
  border: 2px solid var(--confirm-ticket-button-border);
  color: var(--confirm-ticket-button-color);
}

.cobrar-boleto-container .cobrar-boleto-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cobrar-boleto-container .step1-content-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cobrar-boleto-container .step1-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
  row-gap: 1rem;
}

.input-sm {
  width: 12.5rem;
}

.cobrar-boleto-container .loader-div {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.cobrar-boleto-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.options-section-title {
  font-size: 0.875rem;
  font-weight: 400;
}

.options-section-title > span {
  color: #DC3545;
}

.cobrar-boleto-checkbox-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cobrar-boleto-checkbox-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.cobrar-boleto-checkbox-root {
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--checkbox-border);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cobrar-boleto-checkbox-root:hover {
  background-color: var(--primary-color);
}

.cobrar-boleto-checkbox-root[data-state="checked"] {
  background-color: var(--primary-color);
  border-width: 1px;
}

.cobrar-boleto-checkbox-indicator {
  color: var(--checkbox-color);
}

.cobrar-boleto-checkbox-label {
  font-size: 0.875rem;
  font-weight: 600;
}
