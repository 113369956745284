.boleto-container {
  height: 100%;
  color: var(--text-primary);
}

/* HEADER */

.boleto-header {
  padding: 1.5rem 0 5.625rem 8.75rem;
  border-bottom: 1px solid var(--card-color);

  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.boleto-actions {
  color: var(--text-primary);
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;

  display: flex;
  align-items: flex-start;
  gap: 7rem;
}

.boleto-actions > span {
  display: flex;
  align-items: center;
  gap: 1.5rem;

  font-size: 1.375rem;
}

.actions-buttons {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.actions-buttons a {
  all: unset;
  display: inline-block;
}

.actions-buttons > a button {
  max-width: 5.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.actions-buttons > a:disabled, button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.actions-buttons > a button span {
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 14px;
}

.button-icon {
  width: 4.6875rem;
  height: 4.6875rem;
  background-color: var(--pay-ticket-page-button-background);
  border: 2px solid var(--pay-ticket-page-button-border);
  color: var(--pay-ticket-page-button-color);
  border-radius: 9999px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.button-icon-gray {
  background-color: var(--generate-ticket-page-button-background);
  border: 2px solid var(--generate-ticket-page-button-border);
  color: var(--generate-ticket-page-button-color);
}

/* CONTENT */

.boleto-content {
  padding: 2.625rem 14.375rem 4rem 8.75rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  font-family: 'Poppins', sans-serif;
}

.boleto-content > h3 {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.boletos-list {
  max-height: 25rem;

  display: flex;
  flex-direction: column;

  overflow: auto;
}

.boletos-list::-webkit-scrollbar {
  width: 0.2rem;
}

.boletos-list::-webkit-scrollbar-thumb {
  background-color: var(--modal-border-color);
  border-radius: 20px;
}

.empty-boletos {
  text-align: center;
  font-size: 1rem;
  opacity: 0.5;
}
