.login-area{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  position: relative;
}

.login-area h1, .login-area h3{
  text-align: center;
}

.login-area a{
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.5rem;
  color: var(--primary-color);
  padding: 0.5rem 0;
}
