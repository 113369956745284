.pagina-dados > .container{
    width: 1171px;
    height: 752px;
    margin-left: 348px;
    background: var(--bg-secondary-color);
    padding: 0px 54px 0px 28px;
} 

.pagina-dados .container .titulo p{
    font-size: 16px ;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}


.pagina-dados .copiar-dados{
    background: var(--text-primary);
    padding: 20px 24px 20px 42px;
    border-radius: 8px ;
}

.pagina-dados .copiar-dados p{
   font-size: 14px;
}

.pagina-dados .copiar-dados h1{
    color: var(--auxiliary-color);
    font-size: 20px;
    font-weight: 500;
}

.pagina-dados .copiar-dados h2{
    font-size: 20px;
    font-weight: 500;
    margin-top: 0px;
}

.pagina-dados .botao-confirmar button{
    margin: 23px 0px 0px 0px;
    width: 424px;
    border: none;
    background: var(--secondary-color);
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
    border-radius:22px ;
    padding: 10px 10px 10px 10px;
  }
