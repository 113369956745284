.entry-details{
    width: 60rem;
    color: var(--text-primary);
    padding-bottom: 50px;
}

.details-container {
    width: 210mm;
    padding: 2rem 3rem 0;
}

.details-container-dark {
    color: black;
}

.details-container-loading {
    width: 63%;
    height: 42.5625rem;
    padding-left: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.entry-details .title{
    text-align: center;
    margin-bottom: 4rem;
}

.entry-details button{
    display: flex;
    width: 180px;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--share-receipt-button-border);
    color: var(--share-receipt-button-color);
    background: var(--share-receipt-button-background);
}

.entry-details label{
    font-size: 14px;
    font-weight: 400;
    padding: 0px;
}

.entry-details p{
    font-size: 18px;
    font-weight: 500;
}

.entry-details h1{
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.desc-actions-container {
    padding-left: 3rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.desc-actions-container button:hover {
  background: var(--confirm-ticket-button-background);
  border-color: var(--confirm-ticket-button-border);
  color: var(--confirm-ticket-button-color);
}

.loading-btn {
    cursor: wait;
}
