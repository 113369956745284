:root {
  --gray-300: #ADB5BD;
  --blue: #3D8BFD;
}

.boletos-item {
  padding-right: 0.5rem;

  display: flex;
  justify-content: space-between;
}

.boletos-item-content {
  display: flex;
  gap: 1rem;
}

.boletos-item-info {
  width: 40rem;

  display: flex;
  justify-content: space-between;
}

.item-info-principal {
  font-weight: 600;
  letter-spacing: 0.5px;
}

.item-info-principal > span {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.item-info-secondary {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.boletos-item-info > div {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
} 

.boletos-item-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
}

.boletos-item-actions > span {
  color: var(--blue);
  font-weight: 600;
  letter-spacing: 0.5px;
}

.boletos-item-actions > button {
  all: unset;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: var(--gray-300);
  font-size: 0.75rem;
  font-weight: 600;
}
