.pagina-pix{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.pagina-pix .header a{
    text-decoration: none;
}

.pagina-pix .body button{
    margin-right: 10px;
}

.pagina-pix .body a,button{
    text-decoration: none;
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    background-color: transparent;
    border: none;
}

.pagina-pix .body a:hover{
    color: var(--text-primary);
}

.pagina-pix .body a,button p{
    text-align: center;
    width: 100px;
    color: var(--text-primary);
    text-align: center;
    font-family: Poppins;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 14px !important;
    letter-spacing: 1px !important;
    text-transform: uppercase !important;
}

.pagina-pix-container{
    height: 100%;
}

.pagina-pix .pagina-pix-container .header{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-pix .header .col-xl-12 p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.pagina-pix .header .col-xl-12 a{
    color: var(--header-link-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pagina-pix .header .col-xl-12 label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}


.pagina-pix .body{
    height: 350px;
    background: transparent;
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
}

.pagina-pix .body h1 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.pagina-pix .body h2 {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 1px;
}

.pagina-pix .body .svg-circle{
    border: 2px solid var(--text-primary);
    border-radius: 100px;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-circle-color);
}

.pagina-pix .body .svg-circle:not(.disabled-button):hover{
  background-color: var(--bg-circle-hover);
}

.pagina-pix .body .svg-circle:hover svg path{
fill: var(--bg-circle-text-color-hover);
}

.pagina-pix .body .disabled-button {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagina-pix .body .recent-icons{
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    background-color: var(--recent-icons-background);
    color: var(--recent-icons-color);
    border: var(--recent-icons-border);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagina-pix .body .recent-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    margin-top: 15px;
}

.pagina-pix .body .recent-content{
    display: flex;
    flex-direction: column;
}

.pagina-pix .body p{
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.5px;
    margin: 10px 0 5px 0;
}

.pagina-pix .body span{
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
}

.pagina-pix hr{
    background-color: var(--card-color);
    width: 100%;
}

.pagina-pix .footer {
    margin-top: 30px;
}

.pagina-pix .footer h1{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pagina-pix .footer button{
    color: var(--pix-keys-manage-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.pagina-pix .footer .keys-area{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: black transparent;
    height: 320px;
}

.pagina-pix .footer .keys-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.pagina-pix .footer .keys-area::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
}

.pagina-pix .footer .keys-area::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
}

.pagina-pix .disabled{
    background-color: var(--disabled-color) !important;
}

.pix-modal .receipt-header {
    padding: 41px 33px;
}

.pix-modal .receipt-header h1{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px; /* 59.091% */
    letter-spacing: 0.5px;
}

.pix-modal .receipt-header svg{
    margin-bottom: 20px;
}

.pix-modal .receipt-body {
    padding: 0px 33px;
}

.pix-modal .receipt-body label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pix-modal .receipt-body p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.5px;
    width: 80%;
}

.transferencia-pix .is-pix-favorito {
    margin-top: 10px;
}

.transferencia-pix .is-pix-favorito input {
    width: max-content;
    margin-right: 10px;
}

.pix-modal .receipt-body .input-area-icon{
    display: flex;
    justify-content: center;
}

.pix-modal .receipt-body .input-area{
    border-radius: 4px;
    border: 1px solid var(--text-primary);
    background: #FFFFFF;
    display: flex;
    height: 38px;
    align-items: center;
}

.pix-modal .receipt-body .input-area input{
    background: transparent;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
}

.pix-modal .receipt-footer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 70px 33px 49px 33px;
}

.pix-modal .receipt-footer .share-button{
    display: flex;
    flex-direction: row;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    height: 38px;
    width: 340px;
    padding: 8px 16px;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--share-receipt-button-border);
    background:  var(--share-receipt-button-background);
    margin-top: -20px;
    color: var(--share-receipt-button-color);
}
