.change-modal {
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--text-primary);
  font-family: 'Poppins', sans-serif;
  letter-spacing: 0.5px;
}

.overlay-change-password-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.40);
}
