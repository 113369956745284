 
.pagar{
    height: calc(100% - 174px);
}

.pagar>.container {
    width: auto;
    height: 100%;
    margin-left: 20%;
    background: var(--bg-secondary-color);
    padding: 0px 54px 0px 28px;
}

.pagar .container .titulo p {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-gray);
    padding: 23px 0px 0px 5px;
}


.pagar .forma-pagamento {
    background: var(--text-primary);
    padding: 20px 24px 37px 42px;
    border-radius: 8px;
}

.pagar .forma-pagamento a {
    color: black;
    text-decoration: none;
}

.pagar .forma-pagamento h1 {
    color: var(--auxiliary-color);
    font-size: 20px;
    font-weight: 500;
}

.pagar .pagamentos {
    padding: 20px 0px 0px 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 92%;
    border-radius: 30px;
}

.pagar .pagamentos svg {
    font-size: 27px;
    margin: 17px 0px 0px 32px;
}

.pagar .pagamentos h2 {
    font-size: 21px;
    font-weight: 500;
    color: var(--primary-color);
    margin: 0px;
    padding-top: 6px;
}

.pagar .pagamentos p {
    padding: 2px 0px 0px 0px;
    font-size: 13px;
    font-weight: 700;
    color: #908181;
}

.pagar .pagamentos img{
    width: 33px;
    margin: 16px 0px 0px 28px;
}